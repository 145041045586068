import React from 'react';
import './Home.css';
import Album from '../components/Album';
import VideoPlayer from '../components/VideoPlayer';
import CountdownTimer from '../components/CountDown';

function Home() {
  return (
    <div className='home-container'>
      <section className='tajmer'>
        <h1 className='text-big'>Nedräkningen för dansfestivalen i april</h1>
        <CountdownTimer targetDate="2025-04-11T23:59:59" />
      </section>

      <section className='description'>
        <h2 className='section-title'>Bailamigos</h2>
        <p className='home-text'>
          <strong>BailAmigos</strong> är en nystartad dansskola som erbjuder klasser i bachata på flera nivåer, från nybörjare till medel. Vi grundades i augusti 2024 av Ricardo Gonzalez, och vi riktar oss till både barn och vuxna. Vår vision är att skapa en välkomnande och inspirerande plats där dansare kan växa och utvecklas, oavsett tidigare erfarenhet.
        </p>
        <p className='home-text'>
          Hos oss hittar du kurser som passar alla, både för den som vill prova på dans för första gången och för den som vill fördjupa sina kunskaper. Vi erbjuder även sociala danskvällar där vi träffas och dansar för att öva på det vi lärt oss under lektionerna.
        </p>
        <ul className='offer-list'>
          <li>Bachata-klasser för alla nivåer, från nybörjare till medel</li>
          <li>Sociala danskvällar för att öva och umgås</li>
          <li>Eventarrangemang inom bachata, salsa och kizomba</li>
        </ul>
      </section>

      <section className='media-section'>
        <h2 className='section-title'>Bilder från festen</h2>
        <Album />
        <h2 className='section-title'>Video från olika aktiviteter</h2>
        <VideoPlayer link={'Videos/eventCenter.mp4'} className="horizontal-video" />
        <div className="videoRow">
          <VideoPlayer link={'Videos/kursVideo.mp4'} className="vertical-video" />
          <VideoPlayer link={'Videos/eventCenterVertical.mp4'} className="vertical-video" />
          <VideoPlayer link={'Videos/kursHageby.mp4'} className="vertical-video" />
        </div>
      </section>
    </div>
  );
}

export default Home;