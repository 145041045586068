import React from 'react';
import './Fuerza.css';

function Fuerza() {
  return (
    <div className='fuerzaText'>
      <h1 className='fuerzaTitle'>Fuerza Dance Kids – En plats för kreativitet, gemenskap och ledarskap</h1>

      <p className='fuerzaText'>
        Fuerza Dance Kids (FDK) grundades för nio år sedan av Ricardo Gonzalez Vargas med målet att skapa en trygg och inspirerande plats för barn och unga att utveckla sina dansfärdigheter, växa som individer och bli framtida ledare. Ursprungligen startade vi dansgruppen på en av de skolor där jag arbetade, och vår resa har varit helt ideell fram till augusti 2024.
      </p>

      <p className='fuerzaText'>
        Vi har under åren haft förmånen att genomföra flera kreativa projekt, däribland musikvideor som vi publicerat på våra sociala medier. Vår kameraman från Stockholm har varit en viktig del av dessa projekt, och vi har haft honom två gånger om året för att fånga våra dansminnen och skapa en dokumentation av vår utveckling.
      </p>

      <p className='fuerzaText'>
        FDK är en mångsidig grupp där vi arbetar med flera olika dansstilar. Här är det inte ett krav att bli ledare – vi välkomnar alla som vill vara med för att känna samhörighet, gemenskap och skapa nya vänskapsband. Vårt mål är att ge våra medlemmar möjlighet att växa, både som individer och som grupp, och vi vill skapa en miljö där alla känner sig inkluderade och värdefulla, oavsett om man har som mål att ta en ledarroll eller bara vill dansa och ha kul tillsammans.
      </p>

      <p className='fuerzaText'>
        En av våra styrkor är att vi ofta dansar tillsammans med våra elever, vilket ger oss möjlighet att ge varandra feedback och skapa en dynamik där både ledare och deltagare kan utvecklas. Ett av våra största mål är att skapa fler unga ledare genom att ge barn och unga möjlighet att ta egna initiativ och växa i sina roller. Men för oss är det lika viktigt att alla ska känna sig välkomna och ha en plats där de kan vara sig själva och skapa nya vänner.
      </p>

      <p className='fuerzaText'>
        För närvarande har vi tre åldersindelade grupper:
      </p>
      <ul className='fuerzaList'>
        <li>5-7 år</li>
        <li>8-10 år</li>
        <li>11 år och uppåt</li>
      </ul>

      <p className='fuerzaText'>
        Vi ser fram emot att fortsätta växa, skapa nya minnen och inspirera nästa generation – oavsett om de vill vara ledare, skapa gemenskap eller helt enkelt bara dansa och ha roligt!
      </p>
    </div>
  );
}

export default Fuerza;